import React, { Component } from 'react';

class Menubar extends Component {
    menubarEvents = () => {
        document.querySelector('[data-switch-dark]').addEventListener('click', function () {
            document.body.classList.toggle('dark');
        });
    }

    componentDidMount() {
        this.menubarEvents();
    }
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="menubar-fit">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <a className="navbar-brand logo-name" href="/">Daniel Stanlee</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu-toggle"><i className="fas fa-bars"></i></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav menu">
                                <li className="nav-item menu-item">
                                    <a className="nav-link menu-link text-menu" href="#home">Home <span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item menu-item">
                                    <a className="nav-link menu-link text-menu" href="#portfolio-start">Portfolio</a>
                                </li>
                                {/* <li className="nav-item menu-item">
                                    <a className="nav-link menu-link text-menu" href="#recentworks-start">Recent Works</a>
                                </li> */}
                                <li className="nav-item menu-item">
                                    <a className="nav-link menu-link text-menu" href="#contact-form">Contact</a>
                                </li>
                                <li className="nav-item menu-item">
                                    <span className="nav-link menu-link dark-mode-icon text-menu switch" data-switch-dark aria-hidden>Theme Switch <i className="fas fa-adjust"></i></span>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </React.Fragment >
        );

    }
}

export default Menubar;


