import React, { Component } from 'react';

class Contact extends Component {
    state = {}
    render() {
        return (
            <div className="section contact-form" id="contact-form">
                <div className="wrapper contact-form-content">
                    <h2 className="text-first">
                        Contact Information
                    </h2>
                    <div className="contact-content">
                        <div className="basic-contactinfo">
                            <h4 className="text-contact-second">Let's get in touch</h4>
                            <p className="text-grey basic-contactinfo-text">Feel free to Contact me through any of mentioned platforms.Will get in touch as soon as possible</p>
                            <p className="text-grey basic-contactinfo-text">daniestanlee16@gmail.com</p>
                            <p className="text-grey basic-contactinfo-text">+91-9176057961</p>
                        </div>
                        <div className="container-social">
                            <a className="icon" href="https://twitter.com/Danielstanlee16" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a className="icon" href="https://www.facebook.com/daniel.raj.9083/">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a className="icon" href="https://www.linkedin.com/in/daniel-raj-968778134/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                            <a className="icon" href="https://github.com/DanielRaj1610/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-git-alt"></i>
                            </a>
                           
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;