import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

import BacktoTop from './BackToTop';
import Contact from './Contact';
import Experience from './Experience';
import Footer from './Footer';
import Home from './Home';
// import HomeTemp from './home-temp';
import Menubar from './Menubar';
// import Projects from './Projects';
import SkillSet from './SkillSet';

const history = createBrowserHistory();
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App = () => {
    useEffect(() => {
        ReactGA.initialize('G-366KXW34MD');
    }, [])

    return (
        <Router history={history}>
            <React.Fragment>
                <Menubar />
                <BacktoTop />
                <Home />
                <SkillSet />
                <Experience />
                {/* <Projects /> */}
                <Contact />
                <Footer />
            </React.Fragment>

        </Router>
    );
}

export default App;