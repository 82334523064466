import React, { Component } from 'react';



class Footer extends Component {
    state = {}
    render() {
        return (<div className="footer-section">
            <div className="footer">
                <div className="footer-nav">
                    <p className="copyrights">
                        &#169; 2020 Daniel S. All Rights Reserved
                    </p>
                </div>
                <div className="footer-credits">
                    <p className="credits-item-1">
                        Icons from <a href="https://www.iconfinder.com/" target="_blank" rel="noopener noreferrer">Icon Finder</a>
                    </p>
                    <p className="credits-item-1">
                        Color Scheme from <a href="https://coolors.co/" target="_blank" rel="noopener noreferrer">Coolors</a>
                    </p>
                </div>
            </div>
        </div>
        );
    }
}

export default Footer;