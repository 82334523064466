import React, { Component } from 'react';
import dp from '../img/home_img.jpeg';



class Home extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="home-fit" id="home">
                    <div className="hero-v1-section">
                        <div className="wrapper">
                            <div className="hero-v1">
                                <img src={dp}
                                    alt="" className="hero-v1-photo" />
                                <div className="hero-v1-info">
                                    <div className="hero-v1-text">Hello, My name is Daniel. I'm a self-taught webapp developer and a tech enthusiast based in India who loves to</div>
                                    <div className="dynamic-text-wrapper">
                                        <div className="slidingVertical">
                                            <span>Sketch Pencil Potraits <i className="fas fa-pencil-alt"></i></span>
                                            <span>Learn new language <i className="fas fa-language"></i></span>
                                            <span>Travel new places <i className="fas fa-plane"></i></span>
                                            <span>Explore new Technology <i className="fas fa-laptop-code"></i></span>
                                            <span>Try new food <i className="fas fa-hamburger"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-v1-angle"></div>
                    </div>
                    <div className="no-padding homebottom">
                        <div className="wrapper">
                            <div className="panel">
                                <div className="panel-services">
                                    {/* <div className="panel-service text-first">Skills to<br />add</div>
                                    <div className="panel-service text-first">Skills to<br />add</div>
                                    <div className="panel-service text-first">Skills to<br />add</div> */}
                                </div>
                                <a href="mailto:danielstanlee16@gmail.com?subject=Subject" className="panel-contact link-reset">
                                    <div className="circle-contact-info">
                                        <div className="panel-text-label">Let’s get in touch!</div>
                                        <div className="panel-email text-first">danielstanlee16@gmail.com</div>
                                    </div>
                                    <div className="circle-wrapper">
                                        <div className="circle pulse-button">
                                            <img src="https://assets.website-files.com/5f1e9a0a48848197418657d4/5f217fca7eaf488b44b9d307_email-white.svg" alt="" className="panel-contact-icon" />
                                        </div>
                                        <div className="circle-wave-1"></div><div className="circle-wave-2"></div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-padding"></div>
                </div>
            </React.Fragment>
        );
    }
}



export default Home;