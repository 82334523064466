import reactlogo from '../img/reactjs-logo.png';
import nodejslogo from '../img/nodejs-logo.png';
import htmllogo from '../img/html-logo.png';
import sasslogo from '../img/sass-logo.png';
import mongodblogo from '../img/mongodb.svg';
import ScrollReveal from 'scrollreveal';

import React, { Component } from "react";

let scrollEvents = () => {
    let sr = ScrollReveal({ reset: true });

    // Custom Settings
    sr.reveal('.foo-1', { duration: 200 });

    sr.reveal('.skill-big-1', {
        origin: 'left',
        distance:'10px',
        duration: 200,
        easing: 'cubic-bezier(.33,.35,0,1)',
        // delay: 500,
        scale: 0.85
    });

    sr.reveal('.skill-big-2', {
        origin: 'left',
        duration: 500,
        distance:'10px',
        easing: 'cubic-bezier(.33,.35,0,1)',
        delay: 500,
        scale: 0.85
    });

    sr.reveal('.skill-big-3', {
        origin: 'left',
        duration: 500,
        distance:'10px',
        easing: 'cubic-bezier(.33,.35,0,1)',
        delay: 600,
        scale: 0.85
    });
    sr.reveal('.skill-big-4', {
        origin: 'left',
        duration: 500,
        distance:'10px',
        easing: 'cubic-bezier(.33,.35,0,1)',
        delay: 700,
        scale: 0.85
    });
    sr.reveal('.skill-big-5', {
        origin: 'left',
        duration: 500,
        distance:'10px',
        easing: 'cubic-bezier(.33,.35,0,1)',
        delay: 800,
        scale: 0.85
    });
}


class SkillSet extends Component {
    state = {};
    componentDidMount() {
        scrollEvents();
    }
    render() {
        return (
            <React.Fragment>
                <div className="section no-padding-top skillset" id="portfolio-start">
                    <div className="wrapper">
                        <div className="intro margin">
                            <div className="text-label">Portfolio</div>
                            <h2 className="text-first">My Current Skillset</h2>
                        </div>
                        <div className="skills-big">
                            <div className="skill-big skill-big-filter skill-big-1">
                                <img
                                    src={reactlogo}
                                    alt=""
                                    className="skill-big-icon"
                                />
                                <div className="skill-text">React</div>
                                <div
                                    className="skill-progress skill-progress-init"
                                >
                                    75%
								</div>
                            </div>
                            <div className="skill-big skill-big-filter skill-big-2">
                                <img
                                    src={nodejslogo}
                                    alt=""
                                    className="skill-big-icon"
                                />
                                <div className="skill-text">Node.js</div>
                                <div
                                    className="skill-progress skill-progress-init"
                                >
                                    80%
								</div>
                            </div>
                            <div className="skill-big skill-big-filter skill-big-3">
                                <img
                                    src={htmllogo}
                                    alt=""
                                    className="skill-big-icon"
                                />
                                <div className="skill-text">HTML5</div>
                                <div
                                    className="skill-progress skill-progress-init"
                                >
                                    95%
								</div>
                            </div>
                            <div className="skill-big skill-big-filter skill-big-4">
                                <img
                                    src={sasslogo}
                                    alt=""
                                    className="skill-big-icon"
                                />
                                <div className="skill-text">Sass</div>
                                <div
                                    className="skill-progress skill-progress-init"
                                >
                                    90%
								</div>
                            </div>
                            <div className="skill-big skill-big-filter skill-big-5">
                                <img
                                    src={mongodblogo}
                                    alt=""
                                    className="skill-big-icon"
                                />
                                <div className="skill-text">Mongo DB</div>
                                <div
                                    className="skill-progress skill-progress-init"
                                >
                                    65%
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SkillSet;
