import React, { Component } from 'react';
import ReactGa from 'react-ga';
import ScrollReveal from 'scrollreveal';


let scrollEvents = () => {
    let sr = ScrollReveal({ reset: true });

    // Custom Settings
    sr.reveal('.career-grid', {
        origin: 'left',
        distance: '20px',
        duration: 500,
        easing: 'cubic-bezier(.33,.35,0,1)',
        scale: 0.85
    });
    sr.reveal('.job-intro-grid', {
        origin: 'right',
        distance: '20px',
        duration: 500,
        easing: 'cubic-bezier(.33,.35,0,1)',
        scale: 0.85
    });
}

let resumeClickHandler = () => {
    ReactGa.event({
        category: 'Download Resume',
        action: 'User clicked the download Resume Button'
    })
}

class Experience extends Component {
    state = {}
    componentDidMount() {
        scrollEvents();
    }
    render() {
        return (
            <React.Fragment>
                <div className="section no-padding-top experience-section">
                    <div className="wrapper">
                        <div className="w-layout-grid main-grid">
                            <div className="aboutme-grid intro-left sticky">
                                <h2 className="text-first">My Experience</h2>
                                <div className="text-grey text-small">
                                    Have attached my resume in this section which contains my skillset gained over the years, you can download them for your reference.<br />&zwj;
                            </div>
                                <a onClick={resumeClickHandler} href="https://firebasestorage.googleapis.com/v0/b/portfolio-2e0df.appspot.com/o/Daniel's%20Resume%2010%3A20%20.pdf?alt=media&token=a9cc191a-7eb9-4033-bc2a-dc973005b0e9" target="_blank" rel="noopener noreferrer" className="button w-button">Download Resume</a>
                            </div>
                            <div className="experience-grid">
                                <div className="career-block career-block-1">
                                    <div className="career-grid career-date text-first" style={{ opacity: 1 }}>
                                        2019 - Present
                                    </div>
                                    <div className="timeline-grid timeline">
                                        <div className="timeline-dot"></div>
                                    </div>
                                    <div className="job-intro-grid job-info" style={{ opacity: 1 }}>
                                        <h3 className="job-heading text-first">Vitasoft Technologies Pvt LTD.</h3>
                                        <div className="job-description">Working as a Full Stack Web-App Developer, extensively using java for backend and js frameworks for the front-end purposes</div>
                                        <div className="tag-badge job-badge text-first">Software Engineer</div>
                                    </div>
                                </div>
                                <div className="career-block career-block-2">
                                    <div className="career-grid career-date text-first" style={{ opacity: 1 }}>
                                        2015 - 2019
                                    </div>
                                    <div className="timeline-grid timeline">
                                        <div className="timeline-dot"></div>
                                    </div>
                                    <div className="job-intro-grid job-info" style={{ opacity: 1 }}>
                                        <h3 className="job-heading text-first">Loyola-ICAM College of Engineering and Technology (LICET)</h3>
                                        <div className="job-description">Developed interests in the mobile and web application development, helped me grooming my skils by working on hand-on projects.</div>
                                        <div className="tag-badge job-badge text-first">B.Tech,Information Technology</div>
                                    </div>
                                </div>
                                <div className="career-block career-block-3">
                                    <div className="career-grid career-date text-first" style={{ opacity: 1 }}>
                                        2013 - 2015
                                    </div>
                                    <div className="timeline-grid timeline">
                                        <div className="timeline-dot"></div>
                                    </div>
                                    <div className="job-intro-grid job-info" style={{ opacity: 1 }}>
                                        <h3 className="job-heading text-first">Sir Sivaswamy Kalalaya Higher Secondary School,Chennai</h3>
                                        <div className="job-description">Computer science student who was eager, motivated and self-driven to learn programming.</div>
                                        <div className="tag-badge job-badge text-first">HSC (12th)-State Board</div>
                                    </div>
                                </div>
                                <div className="career-block career-block-4">
                                    <div className="career-grid career-date text-first" style={{ opacity: 1 }}>
                                        2011 - 2013
                                    </div>
                                    <div className="timeline-grid timeline">
                                        <div className="timeline-dot"></div>
                                    </div>
                                    <div className="job-intro-grid job-info" style={{ opacity: 1 }}>
                                        <h3 className="job-heading text-first">St.John’s Senior Secondary School and Jr. College,Chennai.</h3>
                                        <div className="job-description">Kid who was ready to explore new things</div>
                                        <div className="tag-badge job-badge text-first">SSLC (10TH)-CBSE</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Experience;