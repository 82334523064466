import React, { Component } from 'react';


const handleScrollVisiblity = () => {
    const scrollToTopButton = document.querySelector('.top-button');

    const scrollFunc = () => {
        let y = window.scrollY;

        if (y > 0) {
            scrollToTopButton.className = "top-button show";
        } else {
            scrollToTopButton.className = "top-button hide";
        }
    };

    window.addEventListener("scroll", scrollFunc);

    const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;

        if (c > 0) {
            window.scrollTo(0, 0);
        }
    };

    scrollToTopButton.onclick = function (e) {
        e.preventDefault();
        scrollToTop();
    }
}

class BacktoTop extends Component {
    state = {}

    componentDidMount() {
        handleScrollVisiblity();
    }

    render() {
        return (<div className="back-to-top">
            <div id="Top" className="top"></div>
            <a href="#Top" className="top-button">
                <div className="top-button-icon">&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </a>
        </div>);
    }
}

export default BacktoTop;

